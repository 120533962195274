import React from 'react';

import Header from './header';
import Github from './github';
import AboutMe from './aboutme';
import Terminal from './terminal';
import Projects from './projects';
import cloud from '../images/cloud.svg';
import cloud2 from '../images/cloud2.svg';
import eraser from '../images/eraser.svg';
import pencil from '../images/pencil.svg';
import code from '../images/code.svg';
import coffee from '../images/coffee.svg';
import cube from '../images/3dcube.svg';
import pyramid from '../images/pyramid.svg';
import { default as rhombus } from '../images/rhombus.svg';
import hexagon from '../images/hexagon.svg';

import './site-layout.css';

const SiteLayout = ({ data }) => {
  return (
    <main>
      <Header />

      <div
        className="section"
        style={{
          backgroundColor: '#E27D5F',
        }}
      >
        <img
          alt=""
          src={eraser}
          style={{
            top: '17%',
            right: '1.5%',
            position: 'absolute',
            display: 'block',
            width: '12%',
          }}
        />
        <img
          alt=""
          src={pencil}
          style={{
            top: '67%',
            position: 'absolute',
            display: 'block',
            width: '12%',
            left: '10%',
          }}
        />
        <img
          alt=""
          src={code}
          style={{
            top: '25%',
            position: 'absolute',
            display: 'block',
            width: '12%',
            left: '2%',
          }}
        />

        <img
          alt=""
          src={coffee}
          style={{
            top: '60%',
            position: 'absolute',
            display: 'block',
            width: '12%',
            right: '5%',
          }}
        />
        <Terminal />
      </div>

      <div
        className="section"
        style={{
          backgroundColor: '#85CDCA',
        }}
      >
        <img
          alt=""
          src={cloud}
          style={{
            top: '15%',
            position: 'absolute',
            display: 'block',
            width: '18%',
            right: '1%',
          }}
        />
        <img
          alt=""
          src={cloud2}
          style={{
            top: '20%',
            position: 'absolute',
            display: 'block',
            width: '25%',
            left: '5%',
          }}
        />

        <img
          alt=""
          src={cloud}
          style={{
            top: '70%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            left: '1%',
          }}
        />

        <img
          alt=""
          src={cloud2}
          style={{
            top: '60%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            right: '1.5%',
          }}
        />

        <Projects data={data} />
      </div>

      <div
        className="section"
        style={{
          backgroundColor: '#EEEEEE',
        }}
      >
        <img
          alt=""
          src={cube}
          style={{
            top: '60%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            left: '-2%',
            rotate: '45deg',
          }}
        />

        <img
          alt=""
          src={cube}
          style={{
            top: '10%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            right: '5%',
            rotate: '75deg',
          }}
        />

        <img
          alt=""
          src={pyramid}
          style={{
            top: '60%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            right: '2%',
            rotate: '25deg',
          }}
        />

        <img
          alt=""
          src={pyramid}
          style={{
            top: '10%',
            position: 'absolute',
            display: 'block',
            width: '15%',
            left: '5%',
            rotate: '65deg',
          }}
        />
        <Github data={data} />
      </div>

      <div
        className="section"
        style={{
          backgroundColor: '#c2c2c2',
        }}
      >
        <img
          alt=""
          src={rhombus}
          style={{
            top: '15%',
            position: 'absolute',
            display: 'block',
            width: '7%',
            left: '-2%',
            rotate: '65deg',
          }}
        />

        <img
          alt=""
          src={hexagon}
          style={{
            top: '20%',
            position: 'absolute',
            display: 'block',
            width: '7%',
            right: '2%',
            rotate: '25deg',
          }}
        />

        <img
          alt=""
          src={rhombus}
          style={{
            top: '70%',
            position: 'absolute',
            display: 'block',
            width: '7%',
            right: '-0.4%',
            rotate: '65deg',
          }}
        />

        <img
          alt=""
          src={hexagon}
          style={{
            top: '60%',
            position: 'absolute',
            display: 'block',
            width: '7%',
            left: '-2%',
            rotate: '25deg',
          }}
        />

        <AboutMe selfie={data.selfie} />
      </div>
    </main>
  );
};

export default SiteLayout;
