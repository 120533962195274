import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import resume from '../../assets/resume.pdf';
import Typist from 'react-typist';
import 'react-typist/dist/Typist';
import './terminal.css';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const padding = (num, size) => {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
};

const showAnswer = (setShow) => {
  setTimeout(() => {
    setShow(true);
  }, 50);
};

const Terminal = () => {
  const fade = useSpring({
    loop: { reverse: true },
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const breakpoints = useBreakpoint();

  const [show0, setShow0] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);

  let date = new Date().getDate();
  let month = padding(new Date().getMonth() + 1, 2);
  let year = new Date().getFullYear();
  let hour = padding(new Date().getHours(), 2);
  let minute = padding(new Date().getMinutes(), 2);
  let seconds = padding(new Date().getSeconds(), 2);

  let speed = 0;

  return (
    <div className="terminal">
      <div className="terminal-top-bar">
        <svg>
          <circle cx="90%" cy="15" r="7px" fill="rgb(239, 93, 100)" />
          <circle cx="83%" cy="15" r="7px" fill="rgb(0, 166, 144)" />
          <circle cx="76%" cy="15" r="7px" fill="rgb(239, 182, 77)" />
        </svg>
      </div>

      <div className="terminal-main">
        <div className="pair">
          <span className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow0)}
            >
              >> <span className="green">cat</span> name.txt
            </Typist>
          </span>
          {show0 && <span className="answer">Vincent Boc</span>}
        </div>

        <div className="pair">
          {show0 && (
            <span className="prompt">
              <Typist
                avgTypingDelay={speed}
                stdTypingDelay={speed}
                cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                onTypingDone={() => showAnswer(setShow1)}
              >
                >> <span className="green">cat</span> education.txt
              </Typist>
            </span>
          )}
          {!breakpoints.tablet && show1 && (
            <span className="answer">B.S Computer Science - UC Davis 2021</span>
          )}

          {breakpoints.tablet && show1 && (
            <span className="answer">
              Bachelor of Science in Computer Science - UC Davis 2021
            </span>
          )}
        </div>

        {!breakpoints.tablet && show1 && (
          <span style={{ whiteSpace: 'nowrap' }} className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow2)}
            >
              >> <span className="green">cat</span> favoriteLanguages.txt
            </Typist>
          </span>
        )}

        <div className="answer">
          {!breakpoints.tablet && show2 && (
            <span className="answer">C++, JavaScript, Python, C#</span>
          )}
        </div>

        {!breakpoints.tablet && show2 && (
          <span style={{ whiteSpace: 'nowrap' }} className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow3)}
            >
              >> <span className="green">cat</span> likes.txt
            </Typist>
          </span>
        )}

        <div className="answer">
          {!breakpoints.tablet && show3 && (
            <span className="answer">
              Full Stack Development, LeetCode, League of Legends, Sushi,{' '}
              <span className="red">You &lt;3</span>
            </span>
          )}
        </div>

        {!breakpoints.tablet && show3 && (
          <span style={{ whiteSpace: 'nowrap' }} className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow4)}
            >
              >> <span className="green">cat</span> email.txt
            </Typist>
          </span>
        )}

        <div className="answer">
          {!breakpoints.tablet && show4 && (
            <span className="answer">
              <a
                className="blue"
                target="_blank"
                rel="noreferrer"
                href="mailto:vincentboccs@gmail.com"
              >
                vincentbocCS@gmail.com
              </a>
            </span>
          )}
        </div>

        {!breakpoints.tablet && show4 && (
          <span style={{ whiteSpace: 'nowrap' }} className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow5)}
            >
              >> <span className="green">cat</span> links.txt
            </Typist>
          </span>
        )}

        <div className="answer">
          {!breakpoints.tablet && show5 && (
            <span className="answer">
              <a
                className="blue"
                rel="noreferrer"
                href="https://github.com/JapanPanda/"
                target="_blank"
              >
                GitHub
              </a>
              ,&nbsp;
              <a
                className="blue"
                rel="noreferrer"
                href="https://www.linkedin.com/in/vincentboc"
                target="_blank"
              >
                LinkedIn
              </a>
              ,&nbsp;
              <a
                className="blue"
                rel="noreferrer"
                href="https://www.vincentboc.org/"
                target="_blank"
              >
                Website
              </a>
            </span>
          )}
        </div>

        {!breakpoints.tablet && show5 && (
          <span style={{}} className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow6)}
            >
              >> <span className="green">wget</span> -o resume.pdf
            </Typist>
          </span>
        )}

        <div className="answer">
          {!breakpoints.tablet && show6 && (
            <span className="answer">
              <a
                className="blue"
                rel="noreferrer"
                href={resume}
                target="_blank"
              >
                resume.pdf
              </a>
            </span>
          )}
        </div>

        {!breakpoints.tablet && show6 && (
          <span className="prompt">
            >><animated.span style={fade}> |</animated.span>
          </span>
        )}

        {/* NON-MOBILE LAYOUTS HERE */}

        {breakpoints.tablet && show1 && (
          <span className="prompt">
            <Typist
              avgTypingDelay={speed}
              stdTypingDelay={speed}
              cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
              onTypingDone={() => showAnswer(setShow2)}
            >
              >> <span className="green">nodejs</span>
            </Typist>
          </span>
        )}

        <div className="nodejs">
          <div className="pair">
            {breakpoints.tablet && show2 && (
              <span className="prompt">
                <Typist
                  avgTypingDelay={speed}
                  stdTypingDelay={speed}
                  cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                  onTypingDone={() => showAnswer(setShow3)}
                >
                  > <span className="green">console.log(</span>
                  vincent.<span className="yellow">favoriteLanguages</span>
                  <span className="green">);</span>
                </Typist>
              </span>
            )}
            {breakpoints.tablet && show3 && (
              <span className="answer">
                ['C++', 'JavaScript', 'Python', 'C#']
              </span>
            )}
          </div>

          <div className="pair">
            {breakpoints.tablet && show3 && (
              <span className="prompt">
                <Typist
                  avgTypingDelay={speed}
                  stdTypingDelay={speed}
                  cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                  onTypingDone={() => showAnswer(setShow4)}
                >
                  > <span className="green">console.log(</span>vincent.
                  <span className="yellow">likes</span>
                  <span className="green">);</span>
                </Typist>
              </span>
            )}
            {breakpoints.tablet && show4 && (
              <span className="answer">
                ['Full Stack Development', 'LeetCode', 'League of Legends',
                'Sushi', '<span className="red">You &lt;3</span>']
              </span>
            )}
          </div>

          <div className="pair">
            {breakpoints.tablet && show4 && (
              <span className="prompt">
                <Typist
                  avgTypingDelay={speed}
                  stdTypingDelay={speed}
                  cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                  onTypingDone={() => showAnswer(setShow5)}
                >
                  > <span className="green">console.log(</span>vincent.
                  <span className="yellow">email</span>
                  <span className="green">);</span>
                </Typist>
              </span>
            )}
            {breakpoints.tablet && show5 && (
              <span className="answer">
                <a
                  className="blue"
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:vincentboccs@gmail.com"
                >
                  vincentbocCS@gmail.com
                </a>
              </span>
            )}
          </div>

          <div className="pair">
            {breakpoints.tablet && show5 && (
              <span className="prompt">
                <Typist
                  avgTypingDelay={speed}
                  stdTypingDelay={speed}
                  cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                  onTypingDone={() => showAnswer(setShow6)}
                >
                  > <span className="green">console.log(</span>vincent.links
                  <span className="green">);</span>
                </Typist>
              </span>
            )}

            {breakpoints.tablet && show6 && (
              <span className="answer">
                ['
                <a
                  className="blue"
                  rel="noreferrer"
                  href="https://github.com/JapanPanda/"
                  target="_blank"
                >
                  GitHub
                </a>
                ', '
                <a
                  className="blue"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/vincentboc"
                  target="_blank"
                >
                  LinkedIn
                </a>
                ', '
                <a
                  className="blue"
                  rel="noreferrer"
                  href="https://www.vincentboc.org/"
                  target="_blank"
                >
                  Website
                </a>
                ']
              </span>
            )}
          </div>
          {breakpoints.tablet && show6 && (
            <span className="prompt">
              <Typist
                avgTypingDelay={speed}
                stdTypingDelay={speed}
                cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                onTypingDone={() => showAnswer(setShow7)}
              >
                > <span className="green">.exit</span>
              </Typist>

              <br />
            </span>
          )}
        </div>
        <div className="pair">
          {show7 && (
            <span className="prompt">
              <Typist
                avgTypingDelay={speed}
                stdTypingDelay={speed}
                cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                onTypingDone={() => showAnswer(setShow8)}
              >
                >> <span className="green">wget</span> -o resume.pdf
                http://www.vincentboc.com/resume.pdf
              </Typist>
            </span>
          )}
          {show8 && (
            <span className="answer">
              <a
                className="blue"
                rel="noreferrer"
                href={resume}
                target="_blank"
              >
                resume.pdf
              </a>{' '}
              100% [======================================&gt;]{' '}
              <span>15,790K --.-KB/s in 0.1s</span>
              <br />
              <span>
                {year + '-' + month + '-' + date}{' '}
                {hour + ':' + minute + ':' + seconds} (943 KB/s) - '
                <a
                  className="blue"
                  rel="noreferrer"
                  href={resume}
                  target="_blank"
                >
                  resume.pdf
                </a>
                ' saved
              </span>
            </span>
          )}
        </div>

        {show8 && (
          <span className="prompt">
            >><animated.span style={fade}> |</animated.span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Terminal;
