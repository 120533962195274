import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import './github.css';
import axios from 'axios';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Github = ({ data }) => {
  const breakpoints = useBreakpoint();
  const token = process.env.GATSBY_GITHUB_TOKEN;
  const [githubData, setGithubData] = useState(null);

  useEffect(() => {
    axios({
      url: 'https://api.github.com/graphql',
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        query: `
        query { 
          repository(name: "sagasu", owner: "JapanPanda") {
                url
                ref(qualifiedName: "dev") {
                  target {
                    ... on Commit {
                      history(first: 10) {
                        edges {
                          node {
                            id
                            messageHeadline
                            author {
                              name
                              avatarUrl
                              date
                            }
                            commitUrl
                          }
                        }
                        totalCount
                      }
                    }
                  }
                }
              }
        }
          `,
      },
    })
      .then((result) => {
        setGithubData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <div style={{ position: 'relative', zIndex: '100' }}>
      <div className="header">
        <h3>CURRENT PROJECT</h3>
        <hr />
      </div>
      {githubData !== null && (
        <div className="project-container">
          <div className="repo-info">
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              rel="noreferrer"
              target="_blank"
              href="https://github.com/JapanPanda/sagasu/tree/dev"
            >
              <Img
                style={{ marginBottom: '5px', height: '55%' }}
                fluid={data.sagasubox.childImageSharp.fluid}
              />
            </a>
            <a
              style={{ textDecoration: 'none', color: '#333333' }}
              rel="noreferrer"
              target="_blank"
              href="https://github.com/JapanPanda/sagasu/tree/dev"
            >
              <h3 style={{ display: 'inline' }}>Sagasu.</h3>
            </a>
            <h3
              style={{
                display: 'inline',
              }}
              className="separator"
            >
              {' '}
              |{' '}
            </h3>
            <a
              style={{ textDecoration: 'none', color: '#333333' }}
              rel="noreferrer"
              target="_blank"
              href="https://github.com/JapanPanda/"
            >
              {' '}
              <h3 style={{ display: 'inline' }}>@JapanPanda</h3>
            </a>
            <p>
              The name of the project, Sagasu (探す), means to find in Japanese.
              Following this definition, Sagasu aims to solve the issue of
              mindlessly searching for new anime to watch by bringing
              recommendations to the user in an intelligent manner. Using
              Recombee, a recommendation engine API, and MyAnimeList (Jikan), an
              online API for anime-related information, it integrates both of
              the solutions to create accurate recommendations to the user. More
              specifically, Sagasu produces recommendations based on the user's
              previous interactions (like, dislike, etc) with anime.
            </p>
            <div className="github-badges">
              <div id="green" className="badge">
                <h5>JavaScript</h5>
              </div>
              <div id="red" className="badge">
                <h5>HTML</h5>
              </div>
              <div id="red" className="badge">
                <h5>CSS</h5>
              </div>
              <div id="blue" className="badge">
                <h5>PERN Stack</h5>
              </div>
              <div id="blue" className="badge">
                <h5>Jikan API</h5>
              </div>
              <div id="blue" className="badge">
                <h5>Recombee API</h5>
              </div>
            </div>
          </div>

          <div className="commit-container">
            <div>
              {breakpoints.tablet && (
                <h3>
                  Recent Commits | Total Commits:{' '}
                  {githubData.data.repository.ref.target.history.totalCount}
                </h3>
              )}

              {!breakpoints.tablet && (
                <h3>
                  Recent Commits <br /> Total Commits:{' '}
                  {githubData.data.repository.ref.target.history.totalCount}
                </h3>
              )}
            </div>
            {(!breakpoints.tablet
              ? githubData.data.repository.ref.target.history.edges.slice(0, 5)
              : githubData.data.repository.ref.target.history.edges
            ).map((commit) => (
              <div
                role="link"
                tabIndex="0"
                onClick={() => window.open(commit.node.commitUrl, '_blank')}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    window.open(commit.node.commitUrl, '_blank');
                  }
                }}
                key={commit.node.id}
                className="commit-info"
              >
                <span className="commit-message">
                  <p>{commit.node.messageHeadline}</p>
                </span>
                <span className="commit-author">
                  <p>{commit.node.author.name}</p>
                  <img alt="" src={commit.node.author.avatarUrl + '&s=48'} />
                </span>
                <div className="break" />
                <span className="commit-date">
                  <p>{commit.node.author.date.slice(0, 10)}</p>
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Github;
