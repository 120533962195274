import React from 'react';
import Img from 'gatsby-image';
import './projects.css';

const Projects = ({ data }) => {
  return (
    <div style={{ height: '100%', zIndex: 100, position: 'relative' }}>
      <div className="header">
        <h3>FEATURED PROJECTS</h3>
        <hr />
      </div>

      <div className="container">
        <div className="card-container">
          <div
            onClick={() =>
              window.open(
                'https://github.com/JapanPanda/sagasu/tree/dev',
                '_blank'
              )
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                window.open(
                  'https://github.com/JapanPanda/DDR-Discord-Bot',
                  '_blank'
                );
              }
            }}
            role="link"
            tabIndex="0"
            className="card"
          >
            <div className="card-image">
              <Img
                style={{ height: '100%' }}
                fluid={data.sagasu.childImageSharp.fluid}
              />
              <span className="card-title">
                <h4>Sagasu</h4>
              </span>
            </div>
            <div className="card-info">
              <div style={{ marginBottom: '0px' }} className="badge">
                <h5>Lead Developer</h5>
              </div>
              <div className="card-summary">
                <h5>
                  Directed and developed Sagasu, an app that brings anime
                  recommendations to the user based on previous interactions on
                  anime. Utilizes Jikan API as well as the Recombee API to
                  utilize AI and Machine Learning for recommendations.
                </h5>
              </div>
              <div className="card-bottom">
                <div id="green" className="badge">
                  <h5>JavaScript</h5>
                </div>
                <div id="red" className="badge">
                  <h5>HTML</h5>
                </div>
                <div id="red" className="badge">
                  <h5>CSS</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>PERN Stack</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>Jikan API</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>Recombee API</h5>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              window.open('https://github.com/JapanPanda/osu-nhauto', '_blank')
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                window.open(
                  'https://github.com/JapanPanda/DDR-Discord-Bot',
                  '_blank'
                );
              }
            }}
            role="link"
            tabIndex="0"
            className="card"
          >
            <div style={{ backgroundColor: 'pink' }} className="card-image">
              <Img
                style={{ height: '100%' }}
                fluid={data.osu.childImageSharp.fluid}
              />
              <span className="card-title">
                <h4>Osu!Nhauto</h4>
              </span>
            </div>
            <div className="card-info">
              <div style={{ marginBottom: '0px' }} className="badge">
                <h5>Developer</h5>
              </div>
              <div className="card-summary">
                <h5>
                  Created and developed a bot that simulates mouse and key
                  inputs to the popular rhythm called "osu!". It is able to
                  perfectly play every song possible regardless of difficulty,
                  and can simulate human playing patterns as well.
                </h5>
              </div>
              <div className="card-bottom">
                <div id="green" className="badge">
                  <h5>C#</h5>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              window.open('https://github.com/JapanPanda/Aqua-Bot', '_blank')
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                window.open('https://github.com/JapanPanda/Aqua-Bot', '_blank');
              }
            }}
            role="link"
            tabIndex="0"
            className="card"
          >
            <div className="card-image">
              <Img
                style={{ height: '100%' }}
                fluid={data.aqua.childImageSharp.fluid}
              />
              <span className="card-title">
                <h4>Aqua Bot</h4>
              </span>
            </div>
            <div className="card-info">
              <div style={{ marginBottom: '0px' }} className="badge">
                <h5>Lead Developer</h5>
              </div>
              <div className="card-summary">
                <h5>
                  Designed and developed a Discord bot that provides premium
                  music bot features such as playing Youtube/Spotify
                  songs/playlists with an intuitive queueing system. What sets
                  this music bot apart from the others is the ability to
                  customize and stack audio effects such as 3d-audio, vaporwave,
                  bass boost, and nightcore.
                </h5>
              </div>
              <div className="card-bottom">
                <div id="green" className="badge">
                  <h5>JavaScript</h5>
                </div>
                <div id="red" className="badge">
                  <h5>Node.js</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>Discord API</h5>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() =>
              window.open(
                'https://github.com/JapanPanda/Notify-Me-Schedule-Builder',
                '_blank'
              )
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                window.open(
                  'https://github.com/JapanPanda/DDR-Discord-Bot',
                  '_blank'
                );
              }
            }}
            role="link"
            tabIndex="0"
            className="card"
          >
            <div className="card-image">
              <Img
                style={{ height: '100%' }}
                fluid={data.ucd.childImageSharp.fluid}
              />
              <span className="card-title">
                <h4>Notify Me! Schedule Builder</h4>
              </span>
            </div>
            <div className="card-info">
              <div style={{ marginBottom: '0px' }} className="badge">
                <h5>Lead Developer</h5>
              </div>
              <div className="card-summary">
                <h5>
                  Created a Node.js application that web scrapes for an open
                  seat is available for selected classes on UC Davis' class
                  registration website. If an available seat is found, then it
                  will send a notification to the user's phone via PushBullet.
                </h5>
              </div>
              <div className="card-bottom">
                <div id="green" className="badge">
                  <h5>JavaScript</h5>
                </div>
                <div id="red" className="badge">
                  <h5>Node.js</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>Cheerio</h5>
                </div>
                <div id="blue" className="badge">
                  <h5>Puppeteer</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
