import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import Layout from '../components/layout';
import MobileLayout from '../components/mobile-layout';
import SEO from '../components/seo';
import SiteLayout from '../components/site-layout';

const IndexPage = ({ data }) => {
  // icons made by freepik on flaticon

  const breakpoints = useBreakpoint();

  return (
    <Layout>
      <SEO title="Vincent Boc" />
      {/* {breakpoints.laptop && <DesktopLayout data={data} />}
      {!breakpoints.laptop && <MobileLayout data={data} />} */}
      <SiteLayout data={data} />
    </Layout>
  );
};

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    selfie: file(relativePath: { eq: "selfie.jpg" }) {
      ...fluidImage
    }
    sagasu: file(relativePath: { eq: "sagasu.png" }) {
      ...fluidImage
    }
    sagasubox: file(relativePath: { eq: "sagasu-box.png" }) {
      ...fluidImage
    }
    ddr: file(relativePath: { eq: "ddr.png" }) {
      ...fluidImage
    }
    osu: file(relativePath: { eq: "osu.png" }) {
      ...fluidImage
    }
    ucd: file(relativePath: { eq: "ucd.jpg" }) {
      ...fluidImage
    }
    aqua: file(relativePath: { eq: "aqua.png" }) {
      ...fluidImage
    }
  }
`;

export default IndexPage;
