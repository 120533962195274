import React from 'react';
import './aboutme.css';
import Img from 'gatsby-image';
import '../../fontawesome/css/all.min.css';

const AboutMe = ({ selfie }) => {
  return (
    <div style={{ position: 'relative', zIndex: 100 }}>
      <div className="header">
        <h3>ABOUT ME</h3>
        <hr />
      </div>

      <div className="aboutme-container">
        <div className="long-info">
          <h3>Who Am I?</h3>
          <p>
            Born in Milpitas, California, I began to experiment with programming
            as a way to interact with what I love the most: computers. Starting
            off with learning C in 9th grade, I quickly expanded out into other
            languages. Throughout college, I found out my biggest passion within
            programming: full stack development.
          </p>

          <h3 style={{ marginTop: '40px' }}>Objective In Life</h3>
          <p>
            My objective is to become a part of an organization that helps out
            society in some way. Additionally, another goal is to create a web
            application that benefits lives and tackles common everyday
            problems. However, my ultimate life-long objective is to never stop
            learning, and expand my knowledge so I don't become a clueless
            grandpa as I grow older.
          </p>

          <h3 style={{ marginTop: '40px' }}>Hobbies</h3>
          <p>
            In my free time, I like to work on any side projects I have. A
            recent hobby I've taken up is competitive programming; albeit I'm
            still a greenhorn. Besides that, I enjoy playing video games such as
            Valorant and League of Legends, spending time with my pet bird, and
            playing the guitar.
          </p>

          <h3 style={{ marginTop: '40px' }}>Skills</h3>
          <p>Arranged greatest to least from how proficient I am:</p>
          <div id="advanced" className="badge">
            <p>C++</p>
          </div>
          <div id="advanced" className="badge">
            <p>C</p>
          </div>
          <div id="advanced" className="badge">
            <p>JavaScript</p>
          </div>
          <div id="advanced" className="badge">
            <p>Node.JS</p>
          </div>
          <div id="advanced" className="badge">
            <p>Linux</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Frontend Development</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Backend Development</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Web Scraping</p>
          </div>
          <div id="intermediate" className="badge">
            <p>React</p>
          </div>
          <div id="beginner" className="badge">
            <p>MongoDB</p>
          </div>
          <div id="beginner" className="badge">
            <p>Java</p>
          </div>
          <div id="beginner" className="badge">
            <p>Python</p>
          </div>
        </div>
        <div className="short-info">
          <Img fluid={selfie.childImageSharp.fluid} />
          <p id="caption">That's me in Japan! (No, I'm not the deer)</p>
          <h4>Vincent Boc</h4>
          <h5>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Milpitas,+CA+95035/data=!4m2!3m1!1s0x808fd1e73ae62a5f:0x7863e1c0ad3aa527"
              className="website-link"
            >
              <i className="fas fa-map-marker-alt"></i> Milpitas, CA
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://github.com/JapanPanda/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-github-square"></i> GitHub
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://vincentboc.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-link"></i> Website
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://www.linkedin.com/in/vincentboc/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i> LinkedIn
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="mailto:vincentbocCS@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-envelope-square"></i> Email
            </a>
          </h5>
        </div>
        <div className="education-info">
          <h3>Education</h3>
          <h5>University of California, Davis (2017-2021)</h5>
          <h5>B.S Computer Science</h5>
          <h5>3.91 GPA Cum Laude</h5>
          <h5 style={{ marginTop: '20px', marginBottom: '10px' }}>
            Relevant Coursework
          </h5>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS20 - Discrete Math</p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS30 - Programming and Problem Solving
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS40 - Software Development and Object-Oriented Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS50 - Computer Organization and Machine-Dependent Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS60 - Data Structures and Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS122A - Algorithm Design and Analysis
            </p>
            <p style={{ float: 'right' }}>A-&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS132 - Probability and Statistics for CS
            </p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS140 - Programming Languages</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS150 - Operating Systems</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS154A - Computer Architecture</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS154B - Computer Architecture</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS171 - Machine Learning</p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS175 - Computer Graphics</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS189F - Databases</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS189L - Data Science</p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS197T - Tutoring for CS</p>
            <p style={{ float: 'right' }}>P&nbsp;&nbsp;</p>
          </div>
        </div>
      </div>

      {/* <div className="aboutme-container">
        <div className="short-info">
          <Img fluid={selfie.childImageSharp.fluid} />
          <p id="caption">That's me in Japan! (No, I'm not the deer)</p>
          <h4>Vincent Boc</h4>
          <h5>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/Milpitas,+CA+95035/data=!4m2!3m1!1s0x808fd1e73ae62a5f:0x7863e1c0ad3aa527"
              className="website-link"
            >
              <i className="fas fa-map-marker-alt"></i> Milpitas, CA
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://github.com/JapanPanda/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-github-square"></i> GitHub
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://vincentboc.org"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-link"></i> Website
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="https://www.linkedin.com/in/vincentboc/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i> LinkedIn
            </a>
          </h5>
          <h5>
            <a
              className="website-link"
              href="mailto:vincentbocCS@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fas fa-envelope-square"></i> Email
            </a>
          </h5>
        </div>
        <div className="long-info">
          <h3>Who Am I?</h3>
          <p>
            Born in Milpitas, California, I began to experiment with programming
            as a way to interact with what I love the most: computers. Starting
            off with learning C in 9th grade, I quickly expanded out into other
            languages. Throughout college, I found out my biggest passion within
            programming: full stack development.
          </p>

          <h3 style={{ marginTop: '40px' }}>Objective In Life</h3>
          <p>
            My objective is to become a part of an organization that helps out
            society in some way. Additionally, another goal is to create a web
            application that benefits lives and tackles common everyday
            problems. However, my ultimate life-long objective is to never stop
            learning, and expand my knowledge so I don't become a clueless
            grandpa as I grow older.
          </p>

          <h3 style={{ marginTop: '40px' }}>Hobbies</h3>
          <p>
            In my free time, I like to work on any side projects I have. A
            recent hobby I've taken up is competitive programming; albeit I'm
            still a greenhorn. Besides that, I enjoy playing video games such as
            Valorant and League of Legends, spending time with my pet bird, and
            playing the guitar.
          </p>

          <h3 style={{ marginTop: '40px' }}>Skills</h3>
          <p>Arranged greatest to least from how proficient I am:</p>
          <div id="advanced" className="badge">
            <p>C++</p>
          </div>
          <div id="advanced" className="badge">
            <p>C</p>
          </div>
          <div id="advanced" className="badge">
            <p>JavaScript</p>
          </div>
          <div id="advanced" className="badge">
            <p>Node.JS</p>
          </div>
          <div id="advanced" className="badge">
            <p>Linux</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Frontend Development</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Backend Development</p>
          </div>
          <div id="intermediate" className="badge">
            <p>Web Scraping</p>
          </div>
          <div id="intermediate" className="badge">
            <p>React</p>
          </div>
          <div id="beginner" className="badge">
            <p>MongoDB</p>
          </div>
          <div id="beginner" className="badge">
            <p>Java</p>
          </div>
          <div id="beginner" className="badge">
            <p>Python</p>
          </div>
        </div>
        <div className="education-info">
          <h3>Education</h3>
          <h5>University of California, Davis (2017-2021)</h5>
          <p>3.94 GPA</p>
          <h5 style={{ marginTop: '20px', marginBottom: '10px' }}>
            Relevant Coursework
          </h5>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS20 - Discrete Math</p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS30 - Programming and Problem Solving
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS40 - Software Development and Object-Oriented Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS50 - Computer Organization and Machine-Dependent Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS60 - Data Structures and Programming
            </p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS122A - Algorithm Design and Analysis
            </p>
            <p style={{ float: 'right' }}>A-&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>
              ECS132 - Probability and Statistics for CS
            </p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS150 - Operating Systems</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS154A - Computer Architecture</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS154B - Computer Architecture</p>
            <p style={{ float: 'right' }}>A+</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS171 - Machine Learning</p>
            <p style={{ float: 'right' }}>A&nbsp;&nbsp;</p>
          </div>
          <div className="class-info">
            <p style={{ width: '90%' }}>ECS197T - Tutoring for CS</p>
            <p style={{ float: 'right' }}>P&nbsp;&nbsp;</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutMe;
