import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './header.css';

const Header = () => (
  <header
    style={{
      margin: '0',
      background: 'rgb(25, 28, 26)',
      // position: 'fixed',
      zIndex: 100,
      width: '100%',
    }}
  >
    <div id="nav-header">
      <h3 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            fontFamily: 'Raleway',
          }}
        >
          Vincent Boc
        </Link>
      </h3>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
